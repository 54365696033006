import { navigate } from 'gatsby';
import React from 'react';

const TrustBox = (props) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

//   <!-- TrustBox widget - Micro Button -->
// <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b757fa0340045cd0c938" data-businessunit-id="5a31a4d4b894c90d84225a75" data-style-height="25px" data-style-width="100%">
//   <a href="https://www.trustpilot.com/review/signmonkey.com" target="_blank" rel="noopener">Trustpilot</a>
// </div>
// <!-- End TrustBox widget -->

  return (
    <div style={{position:"relative"}}>
      <div
          ref={ref} // We need a reference to this element to load the TrustBox in the effect.
          className="trustpilot-widget" // Renamed this to className.
          data-locale="en-US" 
          data-template-id="5419b757fa0340045cd0c938"
          data-businessunit-id="5a31a4d4b894c90d84225a75" 
          data-style-height="24px"
          data-style-width="180px" 
          data-theme="light"
      >
          <a href="https://www.trustpilot.com/review/signmonkey.com" target="_blank">
              Trustpilot
          </a> 
      </div>
      <div 
        onClick={(e) => {
          navigate("/why/reviews");
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{height:"24px", position:"absolute", top:"0px", width:"180px", cursor:"pointer"}}
      >
        &nbsp;
      </div>
    </div>
  );
};

export default TrustBox;