import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import Button from "../buttons/Button"
import GoogleImage from "../GoogleImage"
import TrustPilot from "../TrustPilotSmall"
import { colors, storage } from "../../settings"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const PanelBox = styled.div`
    display: flex;
    padding: 10px 20px;

    > div {
        flex: 1;
        padding: 10px;

        > a.image {
            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
            }

            display: block;
            margin-bottom: 12px;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
`

const Links = styled.div`
    margin: 5px 0px 12px 0px; 

    a {
        padding: 4px 16px;
        display: block;
        margin-bottom: 6px;
        text-decoration: none;
        color: black;

        svg {
            margin-right: 12px;
        }
        &:hover {
            background-color: ${colors.lightGrey};
        }
    }
`
const ReviewsBox = styled.div`
    margin-top: 20px;
`

function WhyPanel(props) {
    return (
        <PanelBox>
            <div className="links">
                <Links>
                    <Link to="/why/pricing/" onClick={props.linkClicked}>
                        <FontAwesomeSvgIcon icon={faChevronRight} />{' '}
                        Factory Direct</Link>
                    <Link to="/why/easy-installation/" onClick={props.linkClicked}>
                        <FontAwesomeSvgIcon icon={faChevronRight} />{' '}
                        Easy Installation</Link>
                    <Link to="/why/fast-production/" onClick={props.linkClicked}>
                        <FontAwesomeSvgIcon icon={faChevronRight} />{' '}
                        5 Day Production *</Link>
                    <Link to="/map/" onClick={props.linkClicked}>
                        <FontAwesomeSvgIcon icon={faChevronRight} />{' '}
                        Find A Sign Near You</Link>
                    <Link to="/blog/" onClick={props.linkClicked}>
                        <FontAwesomeSvgIcon icon={faChevronRight} />{' '}
                        Blog</Link>
                    <Link to="/signencyclopedia/" onClick={props.linkClicked}>
                        <FontAwesomeSvgIcon icon={faChevronRight} />{' '}
                        Sign Encyclopedia</Link>                        
                </Links>
                <ReviewsBox>
                    <TrustPilot />
                </ReviewsBox>
            </div>
            <div>
                <Link to="/why/videos/" onClick={props.linkClicked} className="image">
                    <GoogleImage root lazy src="2020/videos.jpg?v3" />
                </Link>
                <div align="center">
                    <Link to="/why/videos/" className="image" style={{backgroundImage: "url("+ encodeURI(storage.url + "2020/videos.jpg?v3") +")"}}>
                        <Button color="blue" onClick={props.linkClicked} label="See all Videos" center />
                    </Link>
                </div>
            </div>
            <div>
                <Link to="/why/photos/" onClick={props.linkClicked} className="image">
                    <GoogleImage root lazy src="2020/photos.jpg?v3" />
                </Link>
                <div align="center">
                    <Link to="/why/photos/" className="image" style={{backgroundImage: "url("+ encodeURI(storage.url + "2020/photos.jpg?v3") +")"}}>
                        <Button color="blue" onClick={props.linkClicked} label="See all Photos" center />
                    </Link>
                </div>
            </div>
        </PanelBox>
    );
}

export default WhyPanel